<template>
    <v-navigation-drawer
        tag="div"
        class="main-side-drawer"
        :model-value="isOpenDrawerMenu"
        width="400"
        location="right"
        :mobile="true"
        @update:modelValue="onDrawerChange"
        temporary
        touchless
    >
        <template #prepend>
            <v-toolbar
                density="compact"
                color="transparent"
                flat
            >
                <v-btn
                    @click="toggleDrawerMenu()"
                    aria-label="Close Cart Menu"
                    :icon="mdiWindowClose"
                ></v-btn>
                <v-spacer></v-spacer>
                <v-tabs
                    v-model="tab"
                    align-tabs="center"
                    color="secondary"
                    @update:modelValue="onTabChange($event as Nilish<DrawerMenuNames>)"
                    @vue:mounted="onTabsMounted"
                    touchless
                    hide-slider
                >
                    <v-tab
                        value="user"
                        :transition="false"
                        :reverse-transition="false"
                    >
                        <UserIcon
                            size="24"
                        ></UserIcon>
                    </v-tab>
                    <v-tab
                        value="cart"
                        :transition="false"
                        :reverse-transition="false"
                    >
                        <CartIcon
                            size="24"
                        ></CartIcon>
                    </v-tab>
                    <v-tab
                        v-if="isMobile"
                        value="main"
                        :transition="false"
                        :reverse-transition="false"
                    >
                        <v-icon
                            size="24"
                            :icon="mdiMenu"
                        ></v-icon>
                    </v-tab>
                </v-tabs>
            </v-toolbar>
            <v-divider></v-divider>
            <v-window
                :model-value="tab"
                touchless
            >
                <v-window-item
                    value="user"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <UserPanelAccountInfo
                        class="pa-4"
                    ></UserPanelAccountInfo>
                </v-window-item>
                <v-window-item
                    value="cart"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <!-- EMPTY -->
                </v-window-item>
                <v-window-item
                    v-if="isMobile"
                    value="main"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <!-- EMPTY -->
                </v-window-item>
            </v-window>
        </template>
        <v-window
            :model-value="tab"
            touchless
        >
            <v-window-item
                value="user"
                :transition="false"
                :reverse-transition="false"
            >
                <UserPanel></UserPanel>
            </v-window-item>
            <v-window-item
                value="cart"
                :transition="false"
                :reverse-transition="false"
            >
                <CartList
                    :in-menu="true"
                    class="px-4 pt-6"
                ></CartList>
            </v-window-item>
            <v-window-item
                v-if="isMobile"
                value="main"
                :transition="false"
                :reverse-transition="false"
            >
                <!-- <MobileMenu
                    :nav-items="navItems"
                ></MobileMenu> -->
                <MainMenuAccordion
                    class="mt-8"
                    :items="navItems"
                    :multiple="false"
                    show-border-lines
                ></MainMenuAccordion>
            </v-window-item>
        </v-window>
        <template #append>
            <v-window
                :model-value="tab"
                touchless
            >
                <v-window-item
                    value="user"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <v-btn
                        v-if="isLoggedIn"
                        @click="() => (drawerMenus.main = false)"
                        to="/sign-out"
                        color="error darken-2"
                        variant="elevated"
                        size="large"
                        rounded="0"
                        aria-label="Sign Out"
                        flat
                        block
                        nuxt
                    >
                        Sign Out
                    </v-btn>
                </v-window-item>
                <v-window-item
                    value="cart"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <CartActions></CartActions>
                </v-window-item>
                <v-window-item
                    v-if="isMobile"
                    value="main"
                    :transition="false"
                    :reverse-transition="false"
                >
                    <!-- EMPTY -->
                </v-window-item>
            </v-window>
        </template>
    </v-navigation-drawer>
</template>

<script lang="ts" setup>
    import {
        mdiWindowClose,
        mdiMenu
    } from '@mdi/js';
    import {
        drawerMenus,
        toggleDrawerMenu,
        isOpenDrawerMenu,
        openedDrawerMenu,
        useCustomAuth,
        useIsMobile
    } from '~/composables';
    import type {
        DrawerMenuNames,
        MenuItem
    } from '~/types';
    import CartIcon from '~/components/header/menus/CartIcon/CartIcon.vue';
    import UserIcon from '~/components/header/menus/UserIcon/UserIcon.vue';

    // const MobileMenu = defineAsyncComponent(() => import('~/components/header/menus/MobileMenu/MobileMenu.vue'));
    const MainMenuAccordion = defineAsyncComponent(() => import('~/components/menus/MainMenuAccordion/MainMenuAccordion.vue'));
    const CartActions = defineAsyncComponent(() => import('~/components/cart/CartActions/CartActions.vue'));
    const CartList = defineAsyncComponent(() => import('~/components/cart/CartList/CartList.vue'));
    const UserPanel = defineAsyncComponent(() => import('~/components/header/menus/UserPanel/UserPanel.vue'));
    const UserPanelAccountInfo = defineAsyncComponent(() => import('~/components/header/menus/UserPanelAccountInfo/UserPanelAccountInfo.vue'));

    withDefaults(
        defineProps<{
            navItems: Nilable<MenuItem[]>;
        }>(),
        {
            navItems: () => []
        }
    );

    const { isMobile } = useIsMobile();
    const { isLoggedIn } = useCustomAuth();
    const tab = ref<Nilish<DrawerMenuNames>>(null);
    const isReady = ref<boolean>(false);

    const unWatchDrawers = watch(
        openedDrawerMenu,
        (openedTab) => {
            if (isOpenDrawerMenu.value && openedTab) {
                tab.value = openedTab;
            }
        },
        {
            immediate: true
        }
    );

    function onDrawerChange(value: boolean): void {
        if (!value) {
            toggleDrawerMenu();
        }
    }

    function onTabChange(tabName: Nilish<DrawerMenuNames>): void {
        if (isReady.value && tabName) {
            toggleDrawerMenu(tabName, true);
        }
    }

    function onTabsMounted(): void {
        isReady.value = true;
    }

    onBeforeUnmount(() => {
        unWatchDrawers();
    });
</script>

<style lang="scss">
.main-side-drawer {
    .v-tab--selected {
        background-color: rgb(var(--v-theme-accent-lighten-1));
    }
}
</style>
